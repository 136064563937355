import React from 'react';
import Popover from 'react-bootstrap/Popover';

const PopoverMeuDoutor = (
  <Popover id="popover-meu-doutor">
    <Popover.Content>
      <p className="m-0">
        Seus pacientes têm agora um <strong>aplicativo completo</strong>, com tudo o que eles
        precisam. Confirmar ou cancelar consultas, acessar e consultar faturas a vencer, entrar em
        contato pelo whatsapp. <br />
        Sua clínica digital, <strong>moderna e sem complicações.</strong>
      </p>
    </Popover.Content>
  </Popover>
);

export default PopoverMeuDoutor;
