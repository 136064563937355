import clsx from 'clsx';
import React, { ChangeEventHandler } from 'react';
import { Form } from 'react-bootstrap';

import promoAnual from '@/assets/images/promo-anual-black-november.png';
import * as styles from './switch.module.scss';
import { Image } from '@/components';

export interface SwitchProps {
  handleChange: ChangeEventHandler;
  blackNovember?: boolean;
  isAnual: boolean;
}

const Switch = ({ handleChange, isAnual, blackNovember }: SwitchProps) => (
  <div className={clsx(styles.container, { [styles.blackNovember]: blackNovember })}>
    <div>
      <span className={clsx(styles.text, { [styles.active]: !isAnual })}>Pagamento Mensal</span>
      <span className={clsx(styles.xsText, { [styles.active]: !isAnual })}>Mensal</span>
      <Form.Check
        type="switch"
        onChange={handleChange}
        id="custom-switch"
        className={styles.customSwitch}
      />
      <span className={clsx(styles.text, { [styles.active]: isAnual })}>Pagamento Anual</span>{' '}
      <span className={clsx(styles.xsText, { [styles.active]: isAnual })}>Anual</span>{' '}
    </div>
    {!blackNovember && <span className={styles.discount}>(mais econômico)</span>}
    {blackNovember && (
      <Image
        src={promoAnual}
        alt="R$ 549,80 OFF + Alexa"
        title="R$ 549,80 OFF + Alexa"
        className={styles.floatingText}
      />
    )}
  </div>
);

export default Switch;
