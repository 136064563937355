// extracted by mini-css-extract-plugin
export var bahamaBlue = "tag-module--bahama-blue--e4bb5";
export var blue = "tag-module--blue--c74be";
export var buttonBlue = "tag-module--button-blue--5e6ae";
export var container = "tag-module--container--ba736";
export var grey = "tag-module--grey--ed98d";
export var innovation = "tag-module--innovation--e023d";
export var preLaunch = "tag-module--pre-launch--8c9a5";
export var preLaunchBig = "tag-module--pre-launch-big--ec27a";
export var scorpionGrassBlue = "tag-module--scorpion-grass-blue--25f6b";
export var small = "tag-module--small--22ed8";