import React from 'react';
import clsx from 'clsx';

import * as styles from './tag.module.scss';

export interface TagProps {
  text?: string;
  className?: string;
}

const Tag = ({ text = 'Novo', className = '' }: TagProps) => {
  const classes = clsx(styles.container, styles[className]);

  return <div className={classes}>{text}</div>;
};

export default Tag;
