import { PopoversNames } from '@/layouts/Planos/Popovers/constants';
import { precosEPlanosButtonNames } from '@/constants/analytics';
import { isBetweenDateRange } from '@/services/black-november';

export interface PlanoInfo {
  type: 'text' | 'sub' | 'desc';
  name: string;
  text?: string;
}

export interface PlanoFuncionalidade {
  name: string;
  info?: PlanoInfo;
  isNew?: boolean;
  disabled?: boolean;
  small?: boolean;
  soon?: boolean;
  isPreLaunch?: boolean;
}

export interface Plano {
  nome: string;
  descricao: string;
  valor?: number;
  planoFull: string;
  funcionalidades: Array<string | PlanoFuncionalidade>;
  gaMetadata: { tryButtonName: string };
  promo?: number;
  promoDescription?: string;
  mostPopular?: boolean;
  preview?: boolean;
  aPartirDe?: boolean;
  isAnual?: boolean;
  meses?: number;
}

export const PlanoNames = {
  Agenda: 'Agenda',
  Gestao: 'Gestão',
  Vendas: 'Vendas',
  Redes: 'Redes e Franquias',
};

const isBlackNovember = isBetweenDateRange();
let blackNovemberFuncionalidades: PlanoFuncionalidade[] = [];

if (isBlackNovember) {
  blackNovemberFuncionalidades = [
    {
      disabled: true,
      name: 'Alexa Echo Pop',
      info: {
        type: 'sub',
        name: PopoversNames.alexa,
        text: '* Apenas no vendas Anual',
      },
    },
  ];
}

export const planos: Plano[] = [
  {
    nome: PlanoNames.Agenda,
    descricao: 'Para clínicas iniciantes ou focadas em gerenciar a agenda',
    valor: 139.9,
    promo: isBlackNovember ? 90.94 : undefined,
    meses: isBlackNovember ? 10 : undefined,
    planoFull: 'Plano Agenda',
    funcionalidades: [
      'Usuários e armazenamento de imagens ilimitados',
      'Agenda completa com confirmação de consulta*',
      'Alerta de retorno na agenda',
      'Prontuário digital e emissão de documentos',
      'Modelos de Anamnese',
      'Orçamentos em aberto',
      'Odontograma',
      { name: 'Site para sua clínica', isNew: true },
      'Aplicativo para a clínica e para o paciente',
      'Campanhas de marketing automáticas*',
      { name: 'Agenda integrada à Alexa', isNew: true },
    ],
    gaMetadata: {
      tryButtonName: precosEPlanosButtonNames.testarGratisCardAgenda,
    },
  },
  {
    nome: PlanoNames.Gestao,
    descricao: 'Para clínicas que querem crescer em faturamento e ter visão de indicadores',
    valor: 214.9,
    promo: isBlackNovember ? 139.69 : undefined,
    meses: isBlackNovember ? 10 : undefined,
    planoFull: 'Plano Agenda +',
    funcionalidades: [
      { name: 'Análise de Crédito*', isNew: true },
      'Fluxo de caixa e comissionamento',
      'Emissão de boletos bancários',
      'Maquininha de cartão integrada',
      { name: 'Conferência de transações', isNew: true },
      'Ortodontia: controle dos retornos para manutenções',
      'Indicadores de performance',
      'Controle de consultas canceladas',
      'Assinatura Eletrônica para evoluções e anamneses*',
      { name: 'Controle de Prótese', isNew: true },
      'Automação para controle de taxas de maquininha',
    ],
    gaMetadata: {
      tryButtonName: precosEPlanosButtonNames.testarGratisCardGestao,
    },
  },
  {
    nome: PlanoNames.Vendas,
    descricao: 'Para clínicas prontas para vender muito mais e expandir seu negócio',
    valor: 299.9,
    promo: isBlackNovember ? 194.94 : undefined,
    meses: isBlackNovember ? 10 : undefined,
    planoFull: 'Plano Gestão +',
    funcionalidades: [
      ...blackNovemberFuncionalidades,
      'Integração com WhatsApp Web',
      {
        name: 'CRM de agendamentos com alerta de pacientes inativos / cancelamentos de consultas e controle de retornos',
        info: {
          type: 'desc',
          name: PopoversNames.crm,
        },
      },
      'CRM de orçamentos em aberto',
      { name: 'Faceograma feminino e masculino de HOF', isNew: true },
      { name: 'Controle de indicações para captação de novos pacientes', isNew: true },
      'Confirmar consultas gratuitamente pelo app para pacientes',
      'Modelo de Contrato',
      { name: 'Personalizar contratos', isNew: true },
      'Assinatura Eletrônica em contrato*',
      { name: 'Agenda integrada à Alexa', isNew: true },
    ],
    mostPopular: true,
    gaMetadata: {
      tryButtonName: precosEPlanosButtonNames.testarGratisCardVendas,
    },
  },
  {
    nome: PlanoNames.Redes,
    descricao: 'Para negócios em redes ou franquias que querem alavancar seu crescimento',
    planoFull: 'Plano Vendas +',
    funcionalidades: [
      { name: 'B.I. para redes odontológicas', isNew: true },

      {
        name: 'Mais de 30 indicadores com dados centralizados',
      },
      {
        name: 'Relatórios integrados por áreas, processos e profissionais',
      },
      {
        name: 'Acesso Multiclínicas',
        isNew: true,
      },
      {
        name: 'Ranking de performance das unidades',
      },
      {
        name: 'Compartilhamento de dados simplificado e com segurança',
      },
      {
        name: '100% integrado ao Simples Dental',
      },
      'Integração com Whatsapp Web',
    ],
    mostPopular: false,
    preview: true,
    gaMetadata: {
      tryButtonName: precosEPlanosButtonNames.saibaMaisRedes,
    },
  },
];

export const planosAnuais: Plano[] = planos.map(plano => {
  let valor;
  let promo;
  let tryButtonName = precosEPlanosButtonNames.saibaMaisRedes;
  let funcionalidades = plano.funcionalidades;

  switch (plano.nome) {
    case PlanoNames.Agenda:
      valor = 1678.8;
      promo = isBlackNovember ? 1538.9 : undefined;
      tryButtonName = precosEPlanosButtonNames.testarGratisCardAgendaAnual;
      break;
    case PlanoNames.Gestao:
      valor = 2578.8;
      promo = isBlackNovember ? 2363.9 : undefined;
      tryButtonName = precosEPlanosButtonNames.testarGratisCardGestaoAnual;
      break;
    case PlanoNames.Vendas:
      valor = 3298.9;
      promo = isBlackNovember ? 2749.1 : undefined;
      tryButtonName = precosEPlanosButtonNames.testarGratisCardVendasAnual;
      if (isBlackNovember) {
        funcionalidades = funcionalidades.map((f, i) => (i === 0 ? 'Alexa de brinde' : f));
      }
      break;
  }

  return {
    ...plano,
    valor,
    promo,
    meses: undefined,
    isAnual: true,
    promoDescription: undefined,
    funcionalidades,
    gaMetadata: {
      tryButtonName,
    },
  };
});
