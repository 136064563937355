// extracted by mini-css-extract-plugin
export var bahamaBlue = "detalhes-module--bahama-blue--cf6bc";
export var blue = "detalhes-module--blue--791e0";
export var buttonBlue = "detalhes-module--button-blue--e5d8a";
export var descricao = "detalhes-module--descricao--e8daf";
export var detalhes = "detalhes-module--detalhes--94c31";
export var grey = "detalhes-module--grey--17126";
export var info = "detalhes-module--info--427f4";
export var scorpionGrassBlue = "detalhes-module--scorpion-grass-blue--20cbb";
export var separator = "detalhes-module--separator--6911f";
export var tableInfo = "detalhes-module--table-info--b6560";