import React from 'react';
import Popover from 'react-bootstrap/Popover';

const PopoverAutomatizacaoWhatsapp = (
  <Popover id="popover-automatizacao-whatsapp">
    <Popover.Content>
      <p className="m-0">Funcionalidade gratuita, sem necessidade de pagamentos à parte.</p>
    </Popover.Content>
  </Popover>
);

export default PopoverAutomatizacaoWhatsapp;
