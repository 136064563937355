import React from 'react';
import Popover from 'react-bootstrap/Popover';

const PopoverAssinatura = (
  <Popover id="popover-assinatura">
    <Popover.Content>
      <p className="m-0">
        Os pacotes de Assinatura Eletrônica são adquiridos à parte na loja do Simples Dental.
      </p>
    </Popover.Content>
  </Popover>
);

export default PopoverAssinatura;
