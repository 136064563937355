// extracted by mini-css-extract-plugin
export var actions = "planos-module--actions--ba1c8";
export var bahamaBlue = "planos-module--bahama-blue--605aa";
export var blackNovember = "planos-module--black-november--46f3f";
export var blue = "planos-module--blue--4cb2b";
export var buttonBlue = "planos-module--button-blue--36b67";
export var buttons = "planos-module--buttons--9e5a7";
export var comparar = "planos-module--comparar--198d9";
export var container = "planos-module--container--0f6cd";
export var grey = "planos-module--grey--8699e";
export var infoIcon = "planos-module--info-icon--30463";
export var list = "planos-module--list--6d721";
export var scorpionGrassBlue = "planos-module--scorpion-grass-blue--8611e";
export var separator = "planos-module--separator--66d48";