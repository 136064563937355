import React from 'react';
import Table from 'react-bootstrap/Table';

import { Separator } from '@/components';
import { planosDetalhes } from '@/constants/planosDetalhes';
import Tag from '../Tag';
import { CheckCircle } from '@/components/Icons';

import * as styles from './detalhes.module.scss';

const Detalhes = React.forwardRef<HTMLDivElement>((props, ref) => (
  <div className={styles.detalhes} ref={ref}>
    <Separator className={styles.separator} />

    <h2 id="detalhes">Qual é seu plano ideal?</h2>
    <p>
      <strong>Experimente todas as funcionalidades por 7 dias de forma gratuita</strong> sem
      precisar cadastrar o cartão de crédito. Descubra várias oportunidades para seu crescimento.
    </p>

    <Table striped>
      <thead>
        <tr>
          <th>Funcionalidade</th>
          <th>Agenda</th>
          <th>Gestão</th>
          <th>Vendas</th>
        </tr>
      </thead>
      <tbody>
        {planosDetalhes.map(
          ({ descricao, info, agenda, gestao, vendas, isNew, isInnovation, isPreLaunch }) => (
            <tr key={descricao}>
              <td>
                <div className={styles.descricao}>
                  {descricao} {isNew && <Tag />}
                  {(isPreLaunch || isInnovation) && (
                    <Tag
                      text={isPreLaunch ? 'Pré-lancaçamento' : 'Inovação'}
                      className={isPreLaunch ? 'preLaunchBig' : 'innovation'}
                    />
                  )}
                </div>
                <span className={styles.info}>{info}</span>
              </td>
              <td>{agenda ? <CheckCircle /> : <CheckCircle color="#D7D7D7" />}</td>
              <td>{gestao ? <CheckCircle /> : <CheckCircle color="#D7D7D7" />}</td>
              <td>
                {vendas ? (
                  <CheckCircle color="white" />
                ) : (
                  <CheckCircle color="rgba(255, 255, 255, 0.16)" />
                )}
              </td>
            </tr>
          ),
        )}
      </tbody>
    </Table>
    <p className={styles.tableInfo}>
      * Pacotes de mensagens SMS, de Análise de Crédito, de Assinatura eletrônica e de WhatsApp são
      adquiridos à parte.
    </p>
  </div>
));

export default Detalhes;
