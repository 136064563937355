import { Separator } from '@/components';
import React from 'react';
import Container from 'react-bootstrap/Container';
import * as styles from './regraspromo.module.scss';

const RegrasPromo = () => {
  return (
    <Container id="regras" className={styles.container}>
      <span className={styles.subtitle}>BLACK NOVEMBER 2024 - ESPECIAL 10 ANOS</span>
      <h2>**Conheça as regras da promoção</h2>

      <p>
        1. A Promoção de Ganhe uma Alexa mais desconto de 2 meses (16%off) do valor integral no
        Plano Vendas Anual é válida no período de 01/11/2024 a 30/11/2024, para os 200 primeiros
        clientes que: (a) clientes novos que contratarem o Plano Vendas Anual, com início do teste
        de 7 dias grátis da plataforma Simples Dental no período acima indicado, e, após o decurso
        do teste, não realizar o cancelamento, procedendo com a contratação e pagamento do preço
        {';'} e(b) clientes ativos da Simples Dental exclusivamente do Plano Grátis, Agenda e Gestão
        (não permitido para assinantes que estão no Plano Vendas), em dia com todas as suas
        obrigações, que realizarem, no período acima indicado, o upgrade para o Plano Vendas Anual.
        Esta Promoção somente será válida para a contratação ou upgrade do Plano Vendas Anual em
        valor igualmente promocional de R$ 2.749,10, pago à vista ou parcelado em até 12 vezes. A
        Echo Pop com Alexa (brinde) será entregue aos clientes elegíveis até o final do mês de
        janeiro de 2025, no endereço cadastrado por este na área “Dados de Cobrança” da plataforma
        Simples Dental. Vale lembrar que as imagens utilizadas nas peças da promoção são meramente
        ilustrativas e podem ser diferentes do brinde final que será entregue ao cliente elegível.
      </p>
      <p>
        2. A promoção dos planos mensais com desconto de 10 meses com 35% de desconto será válida
        somente para novos usuários/clientes que optarem pela assinatura mensal e válida em todos os
        tipos de planos: Agenda, Gestão e Vendas. Esta promoção é somente válida para contratações
        online de 01/11/2024 até 30/11/2024.
      </p>
      <p>
        3. Para já assinantes do sistema Simples Dental, somente durante o período de 01/11/2024 até
        30/11/2024, existirá a promoção de bônus em dobro no caso da contratação dos pacotes de
        WhatsApp, Assinatura eletrônica, Análise de Crédito e SMS de qualquer quantidade e
        contratados na loja dentro do sistema do Simples Dental. Também para assinantes de planos
        gratuitos, agenda e gestão será possível realizar o upgrade para o plano vendas mensal com o
        desconto de 20% durante os 10 primeiros meses. Para mais informações, entre em contato com
        nossos especialistas pelo canal de comunicação:{' '}
        <a href="mailto:contato@simplesdental.com">contato@simplesdental.com</a>
      </p>
      <Separator className={styles.separator} />
    </Container>
  );
};

export default RegrasPromo;
