export interface Faq {
  key: string;
  question: string;
  answer: string;
}

export const faqs: Faq[] = [
  {
    key: '0',
    question: 'Por quanto tempo posso testar o Simples Dental?',
    answer:
      'Você pode testar o sistema gratuitamente por 7 dias. Ao se cadastrar, você vai entrar no plano Vendas e durante o período de teste você pode trocar de um plano para outro, quantas vezes quiser, para conhecer melhor cada um. No final do teste, você escolhe o plano que mais se adequa à sua necessidade e escolhe sua forma de pagamento.',
  },
  {
    key: '1',
    question: 'A assinatura do Simples Dental tem fidelidade?',
    answer:
      'A transparência é um dos nossos principais pilares e só queremos que você continue conosco se estiver totalmente satisfeito. Assim, no plano mensal não existe contrato de fidelidade e você pode cancelar sua assinatura a qualquer momento, sem custos.',
  },
  {
    key: '2',
    question: 'Eu pago alguma taxa de entrada para usar o Simples Dental?',
    answer:
      'Os planos do Simples Dental não possuem nenhuma surpresa, então você faz o cadastro e já paga a mensalidade vigente e nada mais. O Simples Dental é livre de qualquer taxa.',
  },
  {
    key: '3',
    question: 'Como é o suporte para os clientes da Simples Dental?',
    answer:
      'Nós temos duas equipes de Suporte ao Cliente: a equipe de Suporte que você pode chamar no chat a qualquer momento para tirar dúvidas e fazer sugestões, e a equipe de Sucesso do Cliente, que é mais proativa e faz com que nossos clientes entendam o valor da ferramenta e aproveitem ao máximo a experiência. Eles ajudarão com diversas dicas de como você pode atrair e fidelizar mais pacientes, além de ter uma melhor gestão da clínica, controle financeiro e marketing odontológico. Nossos canais são: chat, e-mail e WhatsApp. Nosso time de Suporte possui uma taxa de 96% de satisfação nos atendimentos e retorno de até 3 minutos.',
  },
  {
    key: '4',
    question: 'Qual a segurança dos meus dados no sistema?',
    answer:
      'O Simples Dental utiliza o mesmo nível de segurança de instituições bancárias, da Nasa e grandes plataformas, como Netflix. Utilizando o melhor servidor do mundo, o AWS, com criptografia de todos os dados e segurança internacional HIPAA. Além disso, tudo ficará salvo na nuvem, então pode ficar tranquilo(a) pois seus dados estão bem seguros.',
  },
  {
    key: '5',
    question: 'Se eu cancelar, o que acontece com meus dados?',
    answer:
      'Caso opte por encerrar nossa parceria, você poderá levar seus dados, afinal são seus!  Você poderá exportar os dados cadastrais dos seus pacientes em planilha excel, por dentro do seu sistema ou poderá solicitar um backup completo de tudo que tem alimentado, a qualquer momento, que enviaremos por e-mail para você. A solicitação do backup completo e prazo de entrega é feito pelo chat, com nosso time de suporte.',
  },
  {
    key: '6',
    question: 'Pode migrar os dados de outro sistema odontológico?',
    answer:
      'Sim, você consegue migrar todos os dados cadastrais dos seus pacientes para o Simples Dental. Para isso, basta conversar com um dos nossos especialistas, via chat, que eles vão te ajudar.',
  },
];
