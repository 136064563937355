export interface PlanoDetalhes {
  descricao: string;
  agenda: boolean;
  gestao: boolean;
  vendas: boolean;
  isNew?: boolean;
  isInnovation?: boolean;
  isPreLaunch?: boolean;
  info?: string;
}

export const planosDetalhes: PlanoDetalhes[] = [
  {
    descricao: 'Agenda integrada à Alexa',
    agenda: true,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Site do Dentista',
    agenda: true,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Aplicativo para clínica e para o paciente',
    agenda: true,
    gestao: true,
    vendas: true,
    isNew: false,
  },
  {
    descricao: 'Odontograma digital',
    agenda: true,
    gestao: true,
    vendas: true,
    isNew: false,
  },
  {
    descricao: 'Orçamento Parcial',
    agenda: true,
    gestao: true,
    vendas: true,
    isNew: false,
  },
  {
    descricao: 'Imagens e usuários ilimitados',
    agenda: true,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Gestão de encaixe na agenda',
    agenda: true,
    gestao: true,
    vendas: true,
    isNew: true,
  },
  {
    descricao: 'Confirmar consultas e lembretes automáticos por WhatsApp (oficial) e SMS*',
    agenda: true,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Mensagem automática para pós-operatório, recuperação de orçamentos e retornos*',
    agenda: true,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Prontuário Digital - anamnese, orçamentos e documentos',
    agenda: true,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Anamneses personalizadas e com alertas',
    agenda: true,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Assinatura eletrônica (anamnese e evoluções)',
    agenda: false,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Controle de Prótese',
    agenda: false,
    gestao: true,
    vendas: true,
    isNew: true,
  },
  {
    descricao: 'Comissão de profissionais',
    agenda: false,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Maquininha de cartão integrada (Saúde Service)',
    agenda: false,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Análise de Crédito*',
    agenda: false,
    gestao: true,
    vendas: true,
    isNew: true,
  },
  {
    descricao: 'Indicadores e relatórios de performance ',
    agenda: false,
    gestao: true,
    vendas: true,
  },
  {
    descricao:
      'Gestão de ticket médio, lucratividade, taxa de comparecimento, aprovação de orçamentos',
    agenda: false,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Ferramenta de Ortodontia exclusiva',
    agenda: false,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Integração com o WhatsApp Web',
    agenda: false,
    gestao: false,
    vendas: true,
  },
  {
    descricao: 'Faceograma feminino e masculino de HOF',
    agenda: false,
    gestao: false,
    vendas: true,
    isNew: true,
  },
  {
    descricao: 'Confirmar consultas gratuitamente pelo App Meu Doutor (para pacientes)',
    agenda: false,
    gestao: false,
    vendas: true,
  },
  {
    descricao: 'Funil de Agendamento e de Vendas',
    agenda: false,
    gestao: false,
    vendas: true,
  },
  {
    descricao: 'Modelos de contratos',
    agenda: false,
    gestao: false,
    vendas: true,
  },
  {
    descricao: 'Assinatura eletrônica (contratos)*',
    agenda: false,
    gestao: false,
    vendas: true,
  },
];
