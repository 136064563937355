import React from 'react';
import Popover from 'react-bootstrap/Popover';

const PopoverCRM = (
  <Popover id="popover-crm">
    <Popover.Content>
      <p className="m-0">CRM: Central de Relacionamento com os pacientes para fidelização</p>
    </Popover.Content>
  </Popover>
);

export default PopoverCRM;
