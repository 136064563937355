import React from 'react';
import Popover from 'react-bootstrap/Popover';
import * as styles from './popover-sms.module.scss';

const PopoverSMS = (
  <Popover id="popover-sms" className={styles.popover}>
    <Popover.Content>
      <h2>
        Pacotes de <strong>SMS</strong> e <strong>WhatsApp</strong> para{' '}
        <strong>confirmação</strong> de consulta e <strong>campanhas de marketing</strong> são
        adquiridos à parte.
      </h2>
      <p>O custo do envio de cada mensagem:</p>

      <ul>
        <li>
          <strong>SMS:</strong> a partir de R$ 0,30 por mensagem
        </li>
        <li>
          <strong>WhatsApp:</strong> a partir de R$ 0,35 por mensagem
        </li>
      </ul>
    </Popover.Content>
  </Popover>
);

export default PopoverSMS;
