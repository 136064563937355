import React from 'react';
import Popover from 'react-bootstrap/Popover';

const PopoverAlexa = (
  <Popover id="popover-alexa">
    <Popover.Content>
      <p className="m-0">
        O dispositivo Alexa Echo Pop está disponível somente na contratação do plano Vendas Anual.{' '}
        <a href="#regras">Saiba mais consultando as regras no site.</a>
      </p>
    </Popover.Content>
  </Popover>
);

export default PopoverAlexa;
